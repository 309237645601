// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bedbug-extermination-js": () => import("./../../../src/pages/bedbug-extermination.js" /* webpackChunkName: "component---src-pages-bedbug-extermination-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cockroach-extermination-js": () => import("./../../../src/pages/cockroach-extermination.js" /* webpackChunkName: "component---src-pages-cockroach-extermination-js" */),
  "component---src-pages-common-household-pests-js": () => import("./../../../src/pages/common-household-pests.js" /* webpackChunkName: "component---src-pages-common-household-pests-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eco-conscious-pest-control-js": () => import("./../../../src/pages/eco-conscious-pest-control.js" /* webpackChunkName: "component---src-pages-eco-conscious-pest-control-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mosquito-extermination-js": () => import("./../../../src/pages/mosquito-extermination.js" /* webpackChunkName: "component---src-pages-mosquito-extermination-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-seasonal-pest-control-js": () => import("./../../../src/pages/seasonal-pest-control.js" /* webpackChunkName: "component---src-pages-seasonal-pest-control-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */)
}

